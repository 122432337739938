export default {
  // DriverTypes
  studentRecentGrad: 'Student / Recent Graduate',
  soloLease: 'Solo / Lease Purchase',
  soloOwner: 'Solo / Owner Operator',
  soloCompany: 'Solo / Company Driver',
  soloInterestedInLease: 'Solo / Interested in Lease Purchase',
  teamLease: 'Team / Lease Purchase',
  teamOwner: 'Team / Owner Operator',
  teamCompany: 'Team / Company Driver',
  teamInterestedInLease: 'Team / Interested in Lease Purchase',
  interestedTeamLease: 'Interested in Team / Lease Purchase',
  interestedTeamOwner: 'Interested in Team  / Owner Operator',
  interestedTeamCompany: 'Interested in Team  / Company Driver',
  interestedTeamInterestedInLease:
    'Interested in Team  / Interested in Lease Purchase',
  companyDriver: 'Company Driver',
  leasePurchase: 'Lease Purchase',
  ownerOperator: 'Owner Operator',
  teamDriver: 'Team Driver',
  interestTeamDriver: 'Solo Interested in Team',

  // JobTypes
  otr: 'Over the Road',
  regional: 'Regional',
  dedicatedlane: 'Dedicated Lane',
  dedicatedaccount: 'Dedicated Account',
  local: 'Local',
  'non-driving': 'Non-Driving',
  route: 'Dedicated Route',
  radius: 'Regional / Radius',

  // TrailerTypes
  dryVan: 'Dry Van',
  reefer: 'Refrigerated (Reefer)',
  flatbed: 'Flatbed',
  tanker: 'Tanker',
  doubtrip: 'Doubles / Triples',
  intermodal: 'Intermodal',
  hhg: 'Household Goods',
  hazmat: 'Hazmat',
  specialized: 'Specialized',
  hauler: 'Auto Hauler',
  dryBulk: 'Dry Bulk & Pneumatic',

  // payType
  fixed: 'Weekly (Fixed)',
  range: 'Weekly (Range)',
  custom: 'Weekly (Custom)',
  monthlyFixed: 'Monthly (Fixed)',
  monthlyRange: 'Monthly (Range)',
  monthlyCustom: 'Monthly (Custom)',
  annuallyFixed: 'Annually (Fixed)',
  annuallyRange: 'Annually (Range)',
  annuallyCustom: 'Annually (Custom)',
  clickForDetails: 'Click For Details',

  // HomeTime
  daily: 'Daily',
  eotherday: 'Every Other Day',
  onedayweek: '1 Day/Wk',
  twodayweek: '2 Days/Wk',
  weekly: 'Weekly',
  weekends: 'Weekends',
  eother2weeks: 'Every 2 Wks',
  eother3weeks: 'Every 3 Wks',
  monthly: 'Monthly',
  xDays: 'Custom',
  calltodiscuss: 'Call to Discuss',
  varies: 'Varies',

  // milesType
  milesperweek: 'Miles per Week',
  milespermonth: 'Miles per Month',

  // experience
  needtraining: 'Need Training',
  intraining: 'In School Now',
  graduatedtraining: 'Graduated Training, No Experience Yet',
  '1-5_months': '1-5 Months',
  '6-11_months': '6-11 Months',
  '12-23_months': '12-23 Months',
  '2_years': '2 Years',
  '3_years': '3 Years',
  '4_years': '4 Years',
  '5+_years': '5+ Years',

  // endorsements
  hEndorsement: 'H - Hazardous Materials',
  nEndorsement: 'N - Tanker Vehicles',
  pEndorsement: 'P - Passengers',
  sEndorsement: 'S - School Bus', // direct only, hardly used
  tEndorsement: 'T - Doubles or Triples', // direct only, hardly used
  xEndorsement: 'X - Combo for HAZMAT and Tank Vehicles', // direct leads only
  twicCard: 'TWIC Card', // direct leads only
};
