<svelte:options tag="cdlc-img" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:Img'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let src = '';
  export let metadata = null;
</script>

{#if _.get(metadata, 'width') && _.get(metadata, 'height')}
  <div
    class="CDLC-Img-ImgWrapper"
    style={`background: ${_.get(metadata, 'bgColor')};`}
    on:click={() => {
      dispatch('clicked');
    }}
  >
    <img
      class="CDLC-Img"
      {src}
      alt=""
      on:load={() => {
        dispatch('loaded');
      }}
    />
  </div>
{:else}
  <img
    class="CDLC-Img"
    alt=""
    {src}
    on:click={() => {
      dispatch('clicked');
    }}
    on:load={() => {
      dispatch('loaded');
    }}
  />
{/if}

<style src="./Img.scss">
</style>
