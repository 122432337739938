<svelte:options tag="cdlc-mobile-post-image-slider" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, onDestroy } from 'svelte';

  const log = debug('cdlc:MobilePostImageSlider');

  export let dispatch = () => {};
  export let images = [];
  export let transitionDuration = 200;
  export let showIndicators = true;
  export let autoplay = false;
  export let delay = 1000;
  export let defaultIndex = 0;
  export let active_item = 0; //readonly
  export let is_vertical = false;

  let activeIndicator = 0,
    maxIndicators = 5,
    indicators,
    items = 0,
    availableSpace = 0,
    topClearence = 0,
    elems,
    diff = 0,
    firstMove = null,
    lastMove = null,
    swipeTime = 0,
    swipeWrapper,
    swipeHandler,
    min = 0,
    transformString = is_vertical
      ? 'translate3d(0, -{{val}}px, 0)'
      : 'translate3d(-{{val}}px, 0, 0)',
    touchingTpl = `
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transform: ${transformString};
    -ms-transform: ${transformString};`,
    non_touchingTpl = `
    -webkit-transition-duration: ${transitionDuration}ms;
    transition-duration: ${transitionDuration}ms;
    -webkit-transform: ${transformString};
    -ms-transform: ${transformString};`,
    touching = false,
    pos_axis = 0,
    page_axis = is_vertical ? 'pageY' : 'pageX',
    dir = 0,
    axis,
    lastAxis;

  let played = defaultIndex || 0;
  let run_interval = false;
  $: indicators = Array(Math.min(items, maxIndicators + 1));
  $: {
    if (autoplay && !run_interval) {
      run_interval = setInterval(changeView, delay);
    }
    if (!autoplay && run_interval) {
      clearInterval(run_interval);
      run_interval = false;
    }
  }

  $: computedAspectRatio = computeAspectRatio(images);

  function update() {
    swipeHandler.style.top = topClearence + 'px';
    let { offsetWidth, offsetHeight } =
      swipeWrapper.querySelector('.swipeable-items');
    availableSpace = is_vertical ? offsetHeight : offsetWidth;
    for (let i = 0; i < items; i++) {
      let _transformValue = availableSpace * i + 'px';
      let _transformString = is_vertical
        ? `translate3d(0, ${_transformValue}, 0)`
        : `translate3d(${_transformValue}, 0, 0)`;
      elems[i].style.transform = _transformString;
    }
    diff = 0;
    if (defaultIndex) {
      changeItem(defaultIndex);
    }
  }
  function init() {
    elems = swipeWrapper.querySelectorAll('.swipeable-item');
    items = elems.length;
    update();
  }
  onMount(() => {
    //init();
    setTimeout(() => {
      init();
    });
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', update);
    }
  });
  onDestroy(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', update);
    }
  });

  function moveHandler(e) {
    if (touching) {
      lastMove = e;

      // unless a straight vertical swipe, stop event
      e.stopImmediatePropagation();
      if (
        panDiffAxis(firstMove, lastMove, 'pageX') > 20 ||
        !panDiffAxis(firstMove, lastMove, 'pageY')
      ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
      }

      let max = availableSpace;
      let _axis = (lastAxis = e.touches
        ? e.touches[0][page_axis]
        : e[page_axis]);
      let _diff = axis - _axis + pos_axis;
      dir = _axis > axis ? 0 : 1;
      if (!dir) {
        _diff = pos_axis - (_axis - axis);
      }
      if (_diff <= max * (items - 1) && _diff >= min) {
        for (let i = 0; i < items; i++) {
          let template = i < 0 ? '{{val}}' : '-{{val}}';
          let _value = max * i - _diff;
          elems[i].style.cssText = touchingTpl
            .replace(template, _value)
            .replace(template, _value);
        }
        diff = _diff;
      }
    }
  }

  function panDiff(a, b) {
    try {
      const x1 = a.touches ? a.touches[0].pageX : a.pageX,
        y1 = a.touches ? a.touches[0].pageY : a.pageY,
        x2 = b.touches ? b.touches[0].pageX : b.pageX,
        y2 = b.touches ? b.touches[0].pageY : b.pageY,
        diff = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));

      return diff || 0;
    } catch (e) {
      return 0;
    }
  }

  function panDiffAxis(a, b, axis) {
    try {
      const x1 = a.touches ? a.touches[0][axis] : a[axis],
        x2 = b.touches ? b.touches[0][axis] : b[axis],
        diff = Math.abs(x1 - x2);

      return diff || 0;
    } catch (e) {
      return 0;
    }
  }

  function endHandler(e) {
    e && e.stopImmediatePropagation();
    e && e.stopPropagation();
    e && e.preventDefault();

    let max = availableSpace;
    touching = false;
    let _axis = lastAxis;
    let totalMovement = Math.abs(axis - _axis);
    let swipe_threshold = 0.1;
    let d_max = diff / max;
    let _target = Math.round(d_max);
    let selectedIndex = activeIndicator;

    axis = null;

    const timeDiff = new Date().getTime() - swipeTime;

    if (panDiff(firstMove, lastMove) < 3 && timeDiff < 150) {
      dispatch('body-image-click', {
        image: images[activeIndicator],
        images: images,
      });
    } else if (totalMovement > swipe_threshold * max) {
      selectedIndex = Math.min(
        Math.max(dir ? selectedIndex + 1 : selectedIndex - 1, 0),
        images.length - 1
      );
    }

    diff = selectedIndex * max;

    pos_axis = diff;

    activeIndicator = diff / max;
    for (let i = 0; i < items; i++) {
      let template = i < 0 ? '{{val}}' : '-{{val}}';
      let _value = max * i - pos_axis;
      elems[i].style.cssText = non_touchingTpl
        .replace(template, _value)
        .replace(template, _value);
    }
    active_item = activeIndicator;
    firstMove = null;
    lastMove = null;
    if (typeof window !== 'undefined') {
      window.removeEventListener('mousemove', moveHandler);
      window.removeEventListener('mouseup', endHandler);
      window.removeEventListener('touchmove', moveHandler);
      window.removeEventListener('touchend', endHandler);
    }
    try {
      swipeHandler.removeEventListener('touchmove', moveHandler);
    } catch (e) {}
  }
  function moveStart(e) {
    let max = availableSpace;
    swipeTime = new Date().getTime();
    touching = true;
    firstMove = lastMove = e;
    axis = e.touches ? e.touches[0][page_axis] : e[page_axis];
    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', moveHandler);
      window.addEventListener('mouseup', endHandler);
      window.addEventListener('touchmove', moveHandler);
      window.addEventListener('touchend', endHandler);
    }
    try {
      swipeHandler.addEventListener('touchmove', moveHandler);
    } catch (e) {}
  }
  function changeItem(item) {
    let max = availableSpace;
    diff = max * item;
    activeIndicator = item;
    endHandler();
  }
  function changeView() {
    changeItem(played);
    played = played < items - 1 ? ++played : 0;
  }
  export function goTo(step) {
    let item = Math.max(0, Math.min(step, indicators.length - 1));
    changeItem(item);
  }
  export function prevItem() {
    let step = activeIndicator - 1;
    goTo(step);
  }
  export function nextItem() {
    let step = activeIndicator + 1;
    goTo(step);
  }

  function computeAspectRatio(images) {
    const firstImage = _.get(images, '0.imageMetadata');

    if (!firstImage) return 100;

    return (
      Math.max(
        (9 / 16) * 100,
        Math.min(170, (firstImage.height / firstImage.width) * 100)
      ) || 100
    );
  }

  function isActiveIndicator(active, i) {
    return active === i;
  }

  function swiperDotsTransform(total, active) {
    if (total < maxIndicators) {
      return `transform: translateX(-50%); left: 50%;`;
    }

    let nShifted = 0;

    if (active >= maxIndicators - 1) {
      // the active on is at least more than the max we show
      nShifted = active + 1 - (maxIndicators - 1);
    }

    let p = _.round((nShifted / total) * 100, 4);
    return `transform: translateX(-${p}%)`;
  }

  function isSmallIndicator(activeIndicator, i, total) {
    return (
      !isActiveIndicator(activeIndicator, i) &&
      // first one if we shifted
      ((activeIndicator >= maxIndicators - 1 &&
        i <= activeIndicator - (maxIndicators - 2)) ||
        // one after the active one if there are many more
        (i === activeIndicator + 1 &&
          i < images.length - 1 &&
          i >= maxIndicators) ||
        // last visibile one before swiping over if many
        (i > activeIndicator &&
          i >= maxIndicators - 1 &&
          total > maxIndicators))
    );
  }
</script>

<div class="swipe-holder" style={`padding-bottom: ${computedAspectRatio}%`}>
  <div class="swipe-panel">
    <div class="swipe-item-wrapper" bind:this={swipeWrapper}>
      <div class="swipeable-items">
        <div class="swipeable-slot-wrapper">
          {#each images as image}
            <div class="swipeable-item">
              {#if image.url}
                <div class="swipeable-count-all-images-wrap">
                  <div class="swipeable-images-count">
                    {images.indexOf(image) + 1 + '/' + images.length}
                  </div>
                </div>
                <cdlc-img
                  src={image.url}
                  metadata={{
                    width: 100,
                    height: computedAspectRatio,
                  }}
                />
              {:else}
                <div class="swipeable-count-all-images-wrap">
                  <div class="swipeable-images-count">
                    {images.indexOf(image) + 1 + '/' + images.length}
                  </div>
                </div>
                <cdlc-img
                  src={image.imageUrl}
                  metadata={{
                    width: 100,
                    height: computedAspectRatio,
                    bgColor: _.get(image, 'imageMetadata.bgColor'),
                  }}
                />
              {/if}
            </div>
          {/each}
        </div>
      </div>
    </div>
    <div
      class="swipe-handler"
      bind:this={swipeHandler}
      on:touchstart={moveStart}
      on:mousedown={moveStart}
    />
  </div>
</div>

{#if showIndicators && indicators.length > 1}
  <div class="swipe-indicator">
    <div
      class="swipe-indicator-inside"
      style={swiperDotsTransform(images.length, activeIndicator)}
    >
      {#each images as image, i}
        <span
          class="dot"
          class:is-small={isSmallIndicator(activeIndicator, i, images.length)}
          class:is-active={isActiveIndicator(activeIndicator, i)}
        >
          &bull;
        </span>
      {/each}
    </div>
  </div>
{/if}

<style src="./MobilePostImageSlider.scss">
</style>
